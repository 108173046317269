import type { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import type { EditorSDK } from '@wix/platform-editor-sdk';

export const componentGfppClickedEventListener = async ({
  editorSDK,
  flowAPI,
  panelsApi,
}: {
  editorSDK: EditorSDK;
  flowAPI: PlatformControllerFlowAPI;
  panelsApi: any;
}) => {
  await editorSDK.addEventListener('widgetGfppClicked', async (event) => {
    await editorSDK.addEventListener('componentGfppClicked', async (event) => {
      // console.log('Event Listener:', event);
      // const biLogger = flowAPI.essentials.biLoggerFactory().logger();
      const { id, componentRef } = event.detail;

      switch (id) {
        case 'open-blocks-panel: <kuw2s>':
          void panelsApi.openBlocksPanel('Edit Text', componentRef);
          break;
      }
    });
  });
};
