import {
  bookingsWidgetPageLoaded,
  bookingsCalendarTimePickerLoad,
  bookingsUouSeeMoreDatesTimesClick,
} from '@wix/bi-logger-wixboost-ugc/v2';
import {
  bookingsAppSettingsOpened,
  bookingsSettingsElementChanged,
  bookingsManageBookingsOnSettingsPanelIsClicked,
} from '@wix/bi-logger-wixboost-users/v2';

export const biReportWidgetLoaded = (biLogger, environment) => {
  // BI 16:540
  const { isEditor, isEditorX } = environment;
  biLogger.report(
    bookingsWidgetPageLoaded({
      is_over_editor: isEditor || isEditorX,
      serviceId: '3f41bb42-3b1e-4f02-9176-166c9f9b96af',
      widget_name: 'next_available_widget',
      origin: 'next_available_widget',
    }),
  );
};

export const biReportWidgetLoadedEmpty = (biLogger, environment) => {
  // BI 16:540
  const { isEditor, isEditorX } = environment;
  biLogger.report(
    bookingsWidgetPageLoaded({
      is_over_editor: isEditor || isEditorX,
      isEmptyState: true,
      widget_name: 'next_available_widget',
      typeOfEmptyState: 'no_availability',
    }),
  );
};
export const reportGfppSettingsClicked = (biLogger) => {
  // BI 16:160
  console.log('EDITOR | Sending BI 16:160');
  biLogger.report(
    bookingsAppSettingsOpened({
      referralInfo: 'next_available_widget',
    }),
  );
};

export const reportSettingsOpenedWithNoServices = (biLogger) => {
  // BI 16:160
  console.log('SETTINGS PANEL | Sending BI 16:160');
  biLogger.report(
    bookingsAppSettingsOpened({
      referralInfo: 'next_available_widget',
      isEmptyState: true,
    }),
  );
};

export const biReportTimePickerLoaded = (biLogger, numberOfSlots) => {
  // BI 16:595
  console.log('UOU | Sending BI 16:595');
  biLogger.report(
    bookingsCalendarTimePickerLoad({
      numberOfSlots,
      pageName: 'next_available_widget',
    }),
  );
};

export const biReportBookingsUouSeeMoreDatesTimesClick = (biLogger) => {
  // BI 16:1162
  console.log('UOU | Sending BI 16:1162');
  biLogger.report(
    bookingsUouSeeMoreDatesTimesClick({
      referralInfo: 'next_available_widget',
      widget_name: 'next_available_widget',
    }),
  );
};
export const reportBookingsSettingsElementChanged = (biLogger) => {
  // BI 16:330
  console.log('SETTINGS PANEL | Sending BI 16:330');
  biLogger.report(
    bookingsSettingsElementChanged({
      appName: 'next_available_widget_settings',
    }),
  );
};

export const reportAddYourFirstServiceButtonClicked = (biLogger) => {
  // BI 16:275
  console.log('SETTINGS PANEL | Sending BI 16:275');
  biLogger.report(
    bookingsManageBookingsOnSettingsPanelIsClicked({
      referral: 'next_available_widget',
    }),
  );
};
